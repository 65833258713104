/*
Copyright 2024 New Vector Ltd.
Copyright 2022 The Matrix.org Foundation C.I.C.

SPDX-License-Identifier: AGPL-3.0-only OR GPL-3.0-only OR LicenseRef-Element-Commercial
Please see LICENSE files in the repository root for full details.
*/

.mx_SplashPage {
    position: relative;
    height: 100%;

    &::before {
        content: "";
        display: block;
        position: absolute;
        z-index: -1;
        opacity: 0.6;
        background-image:
            radial-gradient(
                53.85% 66.75% at 87.55% 0%,
                hsla(250deg, 76%, 71%, 0.261) 0%,
                hsla(250deg, 100%, 88%, 0) 100%
            ),
            radial-gradient(41.93% 41.93% at 0% 0%, hsla(222deg, 29%, 20%, 0.28) 0%, hsla(250deg, 100%, 88%, 0) 100%),
            radial-gradient(100% 100% at 0% 0%, hsla(250deg, 100%, 88%, 0.174) 0%, hsla(0deg, 100%, 86%, 0) 100%),
            radial-gradient(106.35% 96.26% at 100% 0%, hsla(250deg, 100%, 88%, 0.4) 0%, hsla(167deg, 76%, 82%, 0) 100%);
        /* blur to reduce color banding issues due to alpha-blending multiple gradients */
        filter: blur(8px);
        inset: -9px;
        mask:
            /* mask to dither resulting combined gradient */
            url("$(res)/img/noise.png"),
            /* gradient to apply different amounts of dithering to different parts of the gradient */
                linear-gradient(
                    to bottom,
                    /* 10% dithering at the top */ rgba(0, 0, 0, 0.9) 20%,
                    /* 80% dithering at the bottom */ rgba(0, 0, 0, 0.2) 100%
                );
    }
}
