/*
Copyright 2024 New Vector Ltd.
Copyright 2021 Michael Weimann <mail@michael-weimann.eu>
Copyright 2015, 2016 OpenMarket Ltd

SPDX-License-Identifier: AGPL-3.0-only OR GPL-3.0-only OR LicenseRef-Element-Commercial
Please see LICENSE files in the repository root for full details.
*/

.mx_MessageContextMenu {
    .mx_IconizedContextMenu_icon {
        width: 16px;
        height: 16px;
        display: block;

        &::before {
            content: "";
            width: 16px;
            height: 16px;
            display: block;
            mask-position: center;
            mask-size: contain;
            mask-repeat: no-repeat;
        }
    }

    .mx_MessageContextMenu_iconCollapse::before {
        mask-image: url("@vector-im/compound-design-tokens/icons/chevron-up.svg");
    }

    .mx_MessageContextMenu_iconReport::before {
        mask-image: url("@vector-im/compound-design-tokens/icons/error-solid.svg");
    }

    .mx_MessageContextMenu_iconLink::before {
        mask-image: url("@vector-im/compound-design-tokens/icons/link.svg");
    }

    .mx_MessageContextMenu_iconPermalink::before {
        mask-image: url("$(res)/img/element-icons/room/share.svg");
    }

    .mx_MessageContextMenu_iconUnhidePreview::before {
        mask-image: url("$(res)/img/element-icons/settings/appearance.svg");
    }

    .mx_MessageContextMenu_iconOpenInMapSite::before {
        mask-image: url("$(res)/img/external-link.svg");
    }

    .mx_MessageContextMenu_iconEndPoll::before {
        mask-image: url("@vector-im/compound-design-tokens/icons/check.svg");
    }

    .mx_MessageContextMenu_iconForward::before {
        mask-image: url("@vector-im/compound-design-tokens/icons/forward.svg");
    }

    .mx_MessageContextMenu_iconRedact::before {
        mask-image: url("@vector-im/compound-design-tokens/icons/delete.svg");
    }

    .mx_MessageContextMenu_iconResend::before {
        mask-image: url("@vector-im/compound-design-tokens/icons/restart.svg");
    }

    .mx_MessageContextMenu_iconSource::before {
        mask-image: url("@vector-im/compound-design-tokens/icons/inline-code.svg");
    }

    .mx_MessageContextMenu_iconQuote::before {
        mask-image: url("@vector-im/compound-design-tokens/icons/quote.svg");
    }

    .mx_MessageContextMenu_iconPin::before {
        mask-image: url("@vector-im/compound-design-tokens/icons/pin.svg");
    }

    .mx_MessageContextMenu_iconUnpin::before {
        mask-image: url("@vector-im/compound-design-tokens/icons/unpin.svg");
    }

    .mx_MessageContextMenu_iconCopy::before {
        height: 16px;
        mask-image: url($copy-button-url);
        position: relative;
        width: 16px;
    }

    .mx_MessageContextMenu_iconEdit::before {
        mask-image: url("$(res)/img/element-icons/room/message-bar/edit.svg");
    }

    .mx_MessageContextMenu_iconReply::before {
        mask-image: url("@vector-im/compound-design-tokens/icons/reply.svg");
    }

    .mx_MessageContextMenu_iconReplyInThread::before {
        mask-image: url("@vector-im/compound-design-tokens/icons/threads.svg");
    }

    .mx_MessageContextMenu_iconReact::before {
        mask-image: url("$(res)/img/element-icons/room/message-bar/emoji.svg");
    }

    .mx_MessageContextMenu_iconViewInRoom::before {
        mask-image: url("$(res)/img/element-icons/view-in-room.svg");
    }

    .mx_MessageContextMenu_jumpToEvent::before {
        mask-image: url("$(res)/img/element-icons/child-relationship.svg");
    }
}
