/*
Copyright 2024 New Vector Ltd.
Copyright 2021 The Matrix.org Foundation C.I.C.

SPDX-License-Identifier: AGPL-3.0-only OR GPL-3.0-only OR LicenseRef-Element-Commercial
Please see LICENSE files in the repository root for full details.
*/

.mx_ImageSizePanel_radios {
    display: flex;
    flex-direction: row;
    gap: $spacing-16;

    > label {
        margin-right: 68px; /* keep the boxes separate */
        cursor: pointer;
    }

    .mx_ImageSizePanel_size {
        background-color: $quinary-content;
        mask-repeat: no-repeat;
        mask-size: 221px;
        mask-position: center;
        width: 221px;
        height: 148px;
        margin-bottom: 14px; /* move radio button away from bottom edge a bit */

        &.mx_ImageSizePanel_sizeDefault {
            mask: url("$(res)/img/element-icons/settings/img-size-normal.svg");
        }

        &.mx_ImageSizePanel_sizeLarge {
            mask: url("$(res)/img/element-icons/settings/img-size-large.svg");
        }
    }
}
