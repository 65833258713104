/*
Copyright 2024 New Vector Ltd.
Copyright 2020 The Matrix.org Foundation C.I.C.

SPDX-License-Identifier: AGPL-3.0-only OR GPL-3.0-only OR LicenseRef-Element-Commercial
Please see LICENSE files in the repository root for full details.
*/

$PassphraseStrengthHigh: $accent;
$PassphraseStrengthMedium: $username-variant5-color;
$PassphraseStrengthLow: $alert;

progress.mx_PassphraseField_progress {
    appearance: none;
    width: 100%;
    border: 0;
    height: 4px;
    position: absolute;
    top: -10px;
    left: 0;

    @mixin ProgressBarBorderRadius "2px";
    @mixin ProgressBarColour $PassphraseStrengthLow;
    &[value="2"],
    &[value="3"] {
        @mixin ProgressBarColour $PassphraseStrengthMedium;
    }
    &[value="4"] {
        @mixin ProgressBarColour $PassphraseStrengthHigh;
    }
}
