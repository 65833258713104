/*
Copyright 2024 New Vector Ltd.

SPDX-License-Identifier: AGPL-3.0-only OR GPL-3.0-only OR LicenseRef-Element-Commercial
Please see LICENSE files in the repository root for full details.
*/

.mx_SlashCommandHelpDialog .mx_SlashCommandHelpDialog_headerRow h2 {
    margin-bottom: 2px;
}

.mx_SlashCommandHelpDialog .mx_Dialog_content {
    margin-top: 12px;
    margin-bottom: 34px;
}
