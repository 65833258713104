/*
Copyright 2024 New Vector Ltd.
Copyright 2021-2023 x The Matrix.org Foundation C.I.C.

SPDX-License-Identifier: AGPL-3.0-only OR GPL-3.0-only OR LicenseRef-Element-Commercial
Please see LICENSE files in the repository root for full details.
*/

.mx_Heading_h1,
.mx_Heading_h2,
.mx_Heading_h3,
.mx_Heading_h4 {
    margin-inline: unset;
    margin-block: unset;
}

.mx_Heading_h1 {
    font: var(--cpd-font-heading-xl-semibold);
}

.mx_Heading_h2 {
    font: var(--cpd-font-heading-lg-semibold);
}

.mx_Heading_h3 {
    font: var(--cpd-font-heading-md-semibold);
    font-weight: var(--cpd-font-weight-semibold);
}

.mx_Heading_h4 {
    font: var(--cpd-font-heading-sm-semibold);
    font-weight: var(--cpd-font-weight-semibold);
}
