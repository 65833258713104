/*
Copyright 2024 New Vector Ltd.
Copyright 2021 The Matrix.org Foundation C.I.C.

SPDX-License-Identifier: AGPL-3.0-only OR GPL-3.0-only OR LicenseRef-Element-Commercial
Please see LICENSE files in the repository root for full details.
*/

.mx_JoinRuleDropdown {
    margin-bottom: 8px;
    font: var(--cpd-font-body-md-regular);
    color: $primary-content;

    .mx_Dropdown_option {
        font: var(--cpd-font-body-md-regular);
        line-height: $font-32px;
        height: 32px;
        min-height: 32px;

        > div {
            padding-left: 30px;
            position: relative;

            &::before {
                content: "";
                position: absolute;
                height: 16px;
                width: 16px;
                left: 6px;
                top: 8px;
                mask-repeat: no-repeat;
                mask-position: center;
                background-color: $secondary-content;
            }

            &.mx_JoinRuleDropdown_knock::before {
                content: normal;
            }
        }
    }

    .mx_JoinRuleDropdown_invite::before {
        box-sizing: border-box;
        mask-image: url("@vector-im/compound-design-tokens/icons/lock-solid.svg");
        mask-size: contain;
        padding: 1px;
    }

    .mx_JoinRuleDropdown_public::before {
        mask-image: url("@vector-im/compound-design-tokens/icons/public.svg");
    }

    .mx_JoinRuleDropdown_restricted::before {
        mask-image: url("$(res)/img/element-icons/group-members.svg");
        mask-size: contain;
    }

    .mx_JoinRuleDropdown_icon {
        color: $secondary-content;
        position: absolute;
        left: 6px;
        top: 8px;
    }
}
