/*
Copyright 2024 New Vector Ltd.
Copyright 2023 The Matrix.org Foundation C.I.C.

SPDX-License-Identifier: AGPL-3.0-only OR GPL-3.0-only OR LicenseRef-Element-Commercial
Please see LICENSE files in the repository root for full details.
*/

.mx_PollDetailHeader {
    /* override accessiblebutton style */
    font-size: $font-15px !important;
}

.mx_PollDetailHeader_icon {
    height: 16px;
    width: 16px;
    margin-right: $spacing-8;
    vertical-align: middle;
}
