/*
 * Copyright 2024 New Vector Ltd.
 *
 * SPDX-License-Identifier: AGPL-3.0-only OR GPL-3.0-only OR LicenseRef-Element-Commercial
 * Please see LICENSE files in the repository root for full details.
 */

// Red text for the "Do not close this window" warning
.mx_ResetIdentityPanel_warning {
    color: var(--cpd-color-text-critical-primary);
}
