/*
Copyright 2024 New Vector Ltd.
Copyright 2021, 2022 Šimon Brandner <simon.bra.ag@gmail.com>
Copyright 2021 The Matrix.org Foundation C.I.C.

SPDX-License-Identifier: AGPL-3.0-only OR GPL-3.0-only OR LicenseRef-Element-Commercial
Please see LICENSE files in the repository root for full details.
*/

.mx_LegacyCallViewHeader {
    height: 44px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    flex-shrink: 0;
    width: 100%;

    &.mx_LegacyCallViewHeader_pip {
        cursor: grab;
    }
}

.mx_LegacyCallViewHeader_text {
    font-size: 1.2rem;
    font-weight: bold;
    vertical-align: middle;
}

.mx_LegacyCallViewHeader_secondaryCallInfo {
    &::before {
        content: "·";
        margin-left: 6px;
        margin-right: 6px;
    }
}

.mx_LegacyCallViewHeader_controls {
    margin-left: auto;
    display: flex;
    gap: 5px;
}

.mx_LegacyCallViewHeader_button {
    display: inline-block;
    vertical-align: middle;
    cursor: pointer;

    &::before {
        content: "";
        display: inline-block;
        height: 20px;
        width: 20px;
        vertical-align: middle;
        background-color: $secondary-content;
        mask-repeat: no-repeat;
        mask-size: contain;
        mask-position: center;
    }

    &.mx_LegacyCallViewHeader_button_fullscreen {
        &::before {
            mask-image: url("@vector-im/compound-design-tokens/icons/expand.svg");
        }
    }

    &.mx_LegacyCallViewHeader_button_pin {
        &::before {
            mask-image: url("$(res)/img/element-icons/room/pin-upright.svg");
        }
    }

    &.mx_LegacyCallViewHeader_button_expand {
        &::before {
            mask-image: url("@vector-im/compound-design-tokens/icons/pop-out.svg");
        }
    }
}

.mx_LegacyCallViewHeader_callInfo {
    margin-left: 12px;
    margin-right: 16px;
    overflow: hidden;
}

.mx_LegacyCallViewHeader_roomName {
    font-weight: bold;
    font-size: 12px;
    line-height: initial;
    height: 15px;

    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.mx_LegacyCallView_secondaryCall_roomName {
    margin-left: 4px;
}

.mx_LegacyCallViewHeader_icon {
    display: inline-block;
    margin-right: 6px;
    height: 16px;
    width: 16px;
    vertical-align: middle;

    &::before {
        content: "";
        display: inline-block;
        vertical-align: top;

        height: 16px;
        width: 16px;
        background-color: $secondary-content;
        mask-repeat: no-repeat;
        mask-size: contain;
        mask-position: center;
        mask-image: url("$(res)/img/element-icons/call/voice-call.svg");
    }
}
