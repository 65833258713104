/*
Copyright 2019-2024 New Vector Ltd.

SPDX-License-Identifier: AGPL-3.0-only OR GPL-3.0-only OR LicenseRef-Element-Commercial
Please see LICENSE files in the repository root for full details.
*/

.mx_ReactionsRowButton {
    display: inline-flex;
    line-height: $font-20px;
    padding: 1px 6px;
    border: 1px solid var(--cpd-color-gray-400);
    border-radius: 10px;
    background-color: var(--cpd-color-gray-200);
    user-select: none;
    align-items: center;

    &.mx_ReactionsRowButton_selected {
        background-color: $accent-300;
        border-color: $accent-800;
    }

    &.mx_AccessibleButton_disabled {
        cursor: not-allowed;
    }

    .mx_ReactionsRowButton_content {
        max-width: 100px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        padding-right: 4px;
    }
}
