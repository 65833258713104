/*
Copyright 2019-2024 New Vector Ltd.

SPDX-License-Identifier: AGPL-3.0-only OR GPL-3.0-only OR LicenseRef-Element-Commercial
Please see LICENSE files in the repository root for full details.
*/

.mx_SecurityRoomSettingsTab_advancedSection {
    margin-top: $spacing-16;
}

.mx_SecurityRoomSettingsTab_warning {
    display: flex;
    align-items: center;
    column-gap: $spacing-4;
}
