/*
Copyright 2024 New Vector Ltd.
Copyright 2021 Šimon Brandner <simon.bra.ag@gmail.com>
Copyright 2015, 2016 OpenMarket Ltd

SPDX-License-Identifier: AGPL-3.0-only OR GPL-3.0-only OR LicenseRef-Element-Commercial
Please see LICENSE files in the repository root for full details.
*/

.mx_Spinner {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    flex: 1;
}

.mx_MatrixChat_middlePanel .mx_Spinner {
    height: auto;
}

@keyframes spin {
    from {
        transform: rotateZ(0deg);
    }
    to {
        transform: rotateZ(360deg);
    }
}

.mx_Spinner_icon {
    background-color: $quinary-content;
    mask: url("$(res)/img/spinner/spinner-background.svg");
    mask-size: 100%;

    display: flex;
    justify-content: center;
    align-items: center;

    width: 100%;
    height: 100%;

    &::before {
        background-color: $secondary-content;
        mask: url("$(res)/img/spinner/spinner-foreground.svg");
        mask-size: 100%;

        width: 100%;
        height: 100%;

        content: "";
        display: flex;

        animation: 1s linear spin infinite;
    }
}
