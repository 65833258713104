/*
Copyright 2024 New Vector Ltd.
Copyright 2020 The Matrix.org Foundation C.I.C.

SPDX-License-Identifier: AGPL-3.0-only OR GPL-3.0-only OR LicenseRef-Element-Commercial
Please see LICENSE files in the repository root for full details.
*/

.mx_ServerPickerDialog {
    width: 468px;
    box-sizing: border-box;

    .mx_Dialog_content {
        margin-bottom: 0;

        > p {
            color: $secondary-content;
            font: var(--cpd-font-body-md-regular);

            margin: 16px 0;

            &:first-of-type {
                margin-bottom: 40px;
            }

            &:last-of-type {
                margin: 0 24px 24px;
            }
        }

        > h2 {
            font-size: $font-15px;
            font-weight: var(--cpd-font-weight-semibold);
            color: $secondary-content;
            margin: 16px 0 16px 8px;
        }

        > a {
            color: $accent;
            margin-left: 8px;
        }
    }

    .mx_ServerPickerDialog_otherHomeserverRadio {
        input[type="radio"] + div {
            margin-top: auto;
            margin-bottom: auto;
        }
    }

    .mx_ServerPickerDialog_otherHomeserver {
        border-top: none;
        border-left: none;
        border-right: none;
        border-radius: unset;

        > input {
            padding-left: 0;
        }

        > label {
            margin-left: 0;
        }
    }

    .mx_AccessibleButton_kind_primary {
        width: calc(100% - 64px);
        margin: 0 8px;
        padding: 15px 18px;
    }
}
