/*
Copyright 2024 New Vector Ltd.
Copyright 2017 Vector Creations Ltd

SPDX-License-Identifier: AGPL-3.0-only OR GPL-3.0-only OR LicenseRef-Element-Commercial
Please see LICENSE files in the repository root for full details.
*/

.mx_DateSeparator_dateContent {
    padding: 0 25px;
}

.mx_DateSeparator_dateHeading {
    flex: 0 0 auto;
    margin: 0;
    font-size: inherit;
    font-weight: inherit;
    color: inherit;
    text-transform: capitalize;
}

.mx_DateSeparator_jumpToDateMenu {
    display: flex;
}

.mx_DateSeparator_chevron {
    align-self: center;
    width: 16px;
    height: 16px;
    mask-position: center;
    mask-size: contain;
    mask-repeat: no-repeat;
    mask-image: url("@vector-im/compound-design-tokens/icons/chevron-down.svg");
    background-color: var(--cpd-color-icon-secondary);
}
