/*
Copyright 2024 New Vector Ltd.
Copyright 2021 The Matrix.org Foundation C.I.C.

SPDX-License-Identifier: AGPL-3.0-only OR GPL-3.0-only OR LicenseRef-Element-Commercial
Please see LICENSE files in the repository root for full details.
*/

.mx_SpacePublicShare {
    .mx_AccessibleButton {
        @mixin SpacePillButton;

        &.mx_SpacePublicShare_shareButton::before {
            mask-image: url("@vector-im/compound-design-tokens/icons/link.svg");
        }

        &.mx_SpacePublicShare_inviteButton::before {
            mask-image: url("$(res)/img/element-icons/room/invite.svg");
        }
    }
}
