/*
Copyright 2024 New Vector Ltd.
Copyright 2020 The Matrix.org Foundation C.I.C.

SPDX-License-Identifier: AGPL-3.0-only OR GPL-3.0-only OR LicenseRef-Element-Commercial
Please see LICENSE files in the repository root for full details.
*/

.mx_SearchWarning {
    text-align: center;
    padding: 0 16px;

    > * {
        vertical-align: middle;
    }

    > img {
        margin-right: 8px;
    }
}
