/*
Copyright 2019-2024 New Vector Ltd.

SPDX-License-Identifier: AGPL-3.0-only OR GPL-3.0-only OR LicenseRef-Element-Commercial
Please see LICENSE files in the repository root for full details.
*/

.mx_JumpToBottomButton {
    z-index: 1000;
    position: absolute;
    /* 12 because height is 50 but button is only 38 = 12+(50-38) = 24 */
    bottom: 12px;
    right: 24px;
    width: 38px;
    /* give it a fixed height so the badge doesn't make */
    /* it taller and pop upwards when visible */
    height: 50px;
    text-align: center;
}

.mx_JumpToBottomButton_badge {
    position: relative;
    top: -12px;
    border-radius: 16px;
    font-weight: bold;
    font-size: $font-12px;
    line-height: $font-14px;
    text-align: center;
    /* to be able to get it centered */
    /* with text-align in parent */
    display: inline-block;
    padding: 0 4px;
    color: var(--cpd-color-text-on-solid-primary);
    background-color: var(--cpd-color-icon-secondary);
}

.mx_JumpToBottomButton_highlight .mx_JumpToBottomButton_badge {
    background-color: var(--cpd-color-icon-critical-primary);
}

.mx_JumpToBottomButton_scrollDown {
    position: relative;
    display: block;
    height: 38px;
    border-radius: 19px;
    box-sizing: border-box;
    background: $background;
    border: 1.3px solid var(--cpd-color-icon-tertiary);
    cursor: pointer;
}

.mx_JumpToBottomButton_scrollDown::before {
    content: "";
    position: absolute;
    inset: 0;
    mask-image: url("@vector-im/compound-design-tokens/icons/chevron-up.svg");
    mask-repeat: no-repeat;
    mask-size: 24px;
    mask-position: center 6px;
    transform: rotate(180deg);
    background: var(--cpd-color-icon-tertiary);
}
