/*
Copyright 2024 New Vector Ltd.
Copyright 2017 Vector Creations Ltd.

SPDX-License-Identifier: AGPL-3.0-only OR GPL-3.0-only OR LicenseRef-Element-Commercial
Please see LICENSE files in the repository root for full details.
*/

.mx_MEmoteBody {
    white-space: pre-wrap;
    text-align: start;
}

.mx_MEmoteBody_sender {
    cursor: pointer;
}
