/*
Copyright 2024 New Vector Ltd.
Copyright 2023 The Matrix.org Foundation C.I.C.
Copyright 2017 Vector Creations Ltd

SPDX-License-Identifier: AGPL-3.0-only OR GPL-3.0-only OR LicenseRef-Element-Commercial
Please see LICENSE files in the repository root for full details.
*/

.mx_InteractiveAuthEntryComponents {
    .mx_InteractiveAuthEntryComponents_termsSubmit {
        margin-top: 20px;
        margin-bottom: 5px;
        width: 100%;
        box-sizing: border-box; /* prevent overflowing */
    }
}

.mx_InteractiveAuthEntryComponents_msisdnWrapper {
    text-align: center;
}

.mx_InteractiveAuthEntryComponents_msisdnEntry {
    font-size: 200%;
    font-weight: bold;
    border: 1px solid $strong-input-border-color;
    border-radius: 3px;
    width: 6em;
}

.mx_InteractiveAuthEntryComponents_msisdnEntry:focus {
    border: 1px solid $accent;
}

.mx_InteractiveAuthEntryComponents_msisdnSubmit {
    margin-top: 4px;
    margin-bottom: 5px;
}

/* XXX: This should be a common button class */
.mx_InteractiveAuthEntryComponents_msisdnSubmit:disabled {
    background-color: $light-fg-color;
    cursor: default;
}

.mx_InteractiveAuthEntryComponents_termsPolicy {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.mx_InteractiveAuthEntryComponents_passwordSection {
    width: 300px;
}

.mx_InteractiveAuthEntryComponents_sso_buttons {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-top: 20px;

    .mx_AccessibleButton {
        margin-left: 5px;
    }
}

.mx_InteractiveAuthEntryComponents_emailWrapper {
    /* "Resend" button/link */
    .mx_AccessibleButton_kind_link_inline {
        /* We need this to be an inline-block so positioning works correctly */
        display: inline-block !important;

        /* Spinner as end adornment of the "resend" button/link */
        .mx_Spinner {
            /* Spinners are usually block elements, but we need it as inline element */
            display: inline-flex !important;
            /* Spinners by default fill all available width, but we don't want that */
            width: auto !important;
            /* We need to center the spinner relative to the button/link */
            vertical-align: middle !important;
        }
    }
}
