/*
Copyright 2024 New Vector Ltd.
Copyright 2015, 2016 OpenMarket Ltd

SPDX-License-Identifier: AGPL-3.0-only OR GPL-3.0-only OR LicenseRef-Element-Commercial
Please see LICENSE files in the repository root for full details.
*/

:root {
    --RoomView_MessageList-padding: 18px;
}

.mx_RoomView_wrapper {
    display: flex;
    flex-direction: column;
    flex: 1;
    position: relative;
    justify-content: center;
    /* Contain the amount of layers rendered by constraining what actually needs re-layering via css */
    contain: strict;
}

.mx_RoomView {
    word-wrap: break-word;
    display: flex;
    flex-direction: column;
    flex: 1;
    position: relative;

    .mx_MainSplit {
        flex: 1 1 0;
    }

    .mx_MessageComposer {
        width: 100%;
        flex: 0 0 auto;
        margin-right: 2px;
        padding-bottom: 1em;
    }
}

.mx_RoomView_auxPanel_hiddenHighlights {
    border-bottom: 1px solid $primary-hairline-color;
    padding: 10px 26px;
    color: $alert;
    cursor: pointer;
}

.mx_RoomView_messagePanel {
    width: 100%;
    overflow-y: auto;
    flex: 1 1 0;
    overflow-anchor: none;
}

.mx_RoomView_messagePanelSearchSpinner {
    flex: 1;
    background-image: url("$(res)/img/typing-indicator-2x.gif");
    background-position: center 367px;
    background-size: 25px;
    background-repeat: no-repeat;
    position: relative;

    &::before {
        background-color: $info-plinth-fg-color;
        mask: url("@vector-im/compound-design-tokens/icons/search.svg");
        mask-repeat: no-repeat;
        mask-position: center;
        mask-size: 50px;
        content: "";
        position: absolute;
        top: 286px;
        left: 0;
        right: 0;
        height: 50px;
    }
}

.mx_RoomView_body {
    display: flex;
    flex-direction: column;
    flex: 1;
    min-width: 0;

    .mx_RoomView_messagePanel,
    .mx_RoomView_messagePanelSpinner,
    .mx_RoomView_messagePanelSearchSpinner {
        order: 2;
    }

    .mx_RoomView_timeline {
        /* offset parent for mx_RoomView_topUnreadMessagesBar  */
        position: relative;
        flex: 1;
        display: flex;
        flex-direction: column;
        margin-right: calc(var(--container-gap-width) / 2);
    }
}

.mx_RoomView_statusArea {
    width: 100%;
    flex: 0 0 auto;

    max-height: 0px;
    background-color: $background;
    z-index: 1000;
    overflow: hidden;

    transition: all 0.2s ease-out;
}

.mx_RoomView_statusArea_expanded {
    max-height: 100px;
}

.mx_RoomView_statusAreaBox {
    margin: auto;
    min-height: 50px;
}

.mx_RoomView_statusAreaBox_line {
    margin-left: 65px;
    border-top: 1px solid $primary-hairline-color;
    height: 1px;
}

.mx_RoomView_messageListWrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    position: relative;
}

.mx_RoomView_searchResultsPanel {
    .mx_RoomView_messageListWrapper {
        justify-content: flex-start;

        > .mx_RoomView_MessageList > li > ol {
            list-style-type: none;
        }
    }

    a {
        text-decoration: none;
        color: inherit;
    }
}

.mx_RoomView_empty {
    font-size: $font-13px;
    padding: 0 24px;
    margin-right: 30px;
    text-align: center;
    margin-bottom: 80px; /* visually center the content (intentional offset) */
}

.mx_RoomView_MessageList {
    list-style-type: none;
    padding: var(--RoomView_MessageList-padding); /* mx_ProfileResizer depends on this value */
    margin: 0;
    /* needed as min-height is set to clientHeight in ScrollPanel
    to prevent shrinking when WhoIsTypingTile is hidden */
    box-sizing: border-box;

    li {
        clear: both;
    }

    .mx_ScrollPanel & {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;

        content-visibility: auto;
        contain-intrinsic-size: 50px;
    }

    .mx_RoomView--local .mx_ScrollPanel & {
        justify-content: center;
    }
}

.mx_RoomView_inCall {
    .mx_RoomView_statusAreaBox_line {
        margin-top: 2px;
        border: none;
        height: 0px;
    }

    .mx_MessageComposer_wrapper {
        border-top: 2px hidden;
        padding-top: 1px;
    }
}

.mx_MatrixChat_useCompactLayout {
    .mx_RoomView_MessageList {
        margin-bottom: 4px;

        h2 {
            margin-top: 6px;
        }
    }

    .mx_RoomView_statusAreaBox {
        min-height: 42px;
    }
}
