/*
Copyright 2024 New Vector Ltd.
Copyright 2021 The Matrix.org Foundation C.I.C.

SPDX-License-Identifier: AGPL-3.0-only OR GPL-3.0-only OR LicenseRef-Element-Commercial
Please see LICENSE files in the repository root for full details.
*/

.mx_SidebarUserSettingsTab_homeAllRoomsCheckbox {
    margin-left: 24px;

    & + div {
        margin-left: 48px;
    }
}

.mx_SidebarUserSettingsTab_checkbox {
    margin-bottom: $spacing-8;
    /* override checkbox styles */
    label {
        align-items: flex-start !important;
    }

    svg {
        height: 16px;
        width: 16px;
        margin-right: $spacing-8;
        margin-bottom: -1px;
    }
}
