/*
Copyright 2024 New Vector Ltd.
Copyright 2023 Manan Sadana <manancodes.dev@gmail.com>

SPDX-License-Identifier: AGPL-3.0-only OR GPL-3.0-only OR LicenseRef-Element-Commercial
Please see LICENSE files in the repository root for full details.
*/

.mx_LogoutDialog_ExportKeyAdvanced {
    width: fit-content;
}
