/*
Copyright 2024 New Vector Ltd.
Copyright 2024 The Matrix.org Foundation C.I.C.

SPDX-License-Identifier: AGPL-3.0-only OR GPL-3.0-only OR LicenseRef-Element-Commercial
Please see LICENSE files in the repository root for full details.
*/

.mx_EmptyState {
    height: 100%;
    box-sizing: border-box;
    padding: var(--cpd-space-4x);
    text-align: center;

    svg {
        width: 56px;
        height: 56px;
        box-sizing: border-box;
        border-radius: 8px;
        padding: var(--cpd-space-3x);
        background-color: $panel-actions;
    }

    &::before {
        /* Bloom using magic numbers directly out of Figma */
        content: "";
        position: absolute;
        z-index: -1;
        width: 642px;
        height: 775px;
        right: -253.77px;
        top: 0;
        background: radial-gradient(49.95% 49.95% at 50% 50%, rgba(13, 189, 139, 0.12) 0%, rgba(18, 115, 235, 0) 100%);
        transform: rotate(-89.69deg);
        overflow: hidden;
    }
}
