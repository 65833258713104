/*
Copyright 2024 New Vector Ltd.
Copyright 2022 The Matrix.org Foundation C.I.C.

SPDX-License-Identifier: AGPL-3.0-only OR GPL-3.0-only OR LicenseRef-Element-Commercial
Please see LICENSE files in the repository root for full details.
*/

.mx_JumpToDatePicker_form {
    display: flex;
    /* This matches the default padding of IconizedContextMenuOption */
    /* (see context_menus/_IconizedContextMenu.pcss) */
    padding-top: 12px;
    padding-bottom: 12px;
}

.mx_JumpToDatePicker_label {
    align-self: center;
    font-size: $font-15px;
}

.mx_JumpToDatePicker_datePicker {
    margin: 0;
    margin-left: 8px;

    &,
    & > input {
        border-radius: 8px;
    }
}

.mx_JumpToDatePicker_submitButton {
    margin-left: 8px;
}
