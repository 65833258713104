/*
Copyright 2024 New Vector Ltd.
Copyright 2015-2021 The Matrix.org Foundation C.I.C.

SPDX-License-Identifier: AGPL-3.0-only OR GPL-3.0-only OR LicenseRef-Element-Commercial
Please see LICENSE files in the repository root for full details.
*/

.mx_MFileBody_download {
    color: $accent;
    height: var(--cpd-space-9x);
}

.mx_MFileBody_download object {
    margin-left: -16px;
    padding-right: 4px;
    margin-top: -4px;
    vertical-align: middle;
    pointer-events: none;
}

/* Remove the border and padding for iframes for download links. */
.mx_MFileBody_download iframe {
    margin: 0px;
    padding: 0px;
    border: none;
    width: 100%;
}

.mx_MFileBody_info {
    cursor: pointer;

    .mx_MFileBody_info_icon {
        background-color: $system;
        border-radius: 20px;
        display: inline-block;
        width: 32px;
        height: 32px;
        position: relative;
        vertical-align: middle;
        margin-right: 12px;

        &::before {
            content: "";
            mask-repeat: no-repeat;
            mask-position: center;
            mask-size: cover;
            mask-image: url("$(res)/img/element-icons/room/composer/attach.svg");
            background-color: $secondary-content;
            width: 15px;
            height: 15px;

            position: absolute;
            top: 8px;
            left: 8px;
        }
    }

    .mx_MFileBody_info_filename {
        font: var(--cpd-font-body-md-regular);
        color: var(--cpd-color-text-primary);
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        display: inline-block;
        width: calc(100% - 32px - 12px); /* 32px icon, 12px margin on the icon */
        vertical-align: middle;
    }
}
