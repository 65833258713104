/*
Copyright 2024 New Vector Ltd.
Copyright 2015, 2016 OpenMarket Ltd

SPDX-License-Identifier: AGPL-3.0-only OR GPL-3.0-only OR LicenseRef-Element-Commercial
Please see LICENSE files in the repository root for full details.
*/

.mx_ViewSource {
    pre {
        font-size: $font-12px;
        padding: 0.5em 1em;
        word-wrap: break-word;
        white-space: pre-wrap;
        overflow-wrap: anywhere;
    }

    .mx_ViewSource_header {
        border-bottom: 1px solid $quinary-content;
        padding-bottom: $spacing-12;
        margin-bottom: $spacing-12;
        font-family: monospace;

        .mx_CopyableText {
            word-break: break-all;
        }
    }

    .mx_ViewSource_heading {
        font-size: $font-17px;
        font-weight: 400;
        color: $primary-content;
        margin-top: $spacing-12;
    }

    .mx_ViewSource_details {
        margin-top: $spacing-12;
    }

    .mx_CopyableText_border {
        box-sizing: border-box;
        width: 100%;
    }
}
