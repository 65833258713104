/*
Copyright 2024 New Vector Ltd.
Copyright 2022 The Matrix.org Foundation C.I.C.

SPDX-License-Identifier: AGPL-3.0-only OR GPL-3.0-only OR LicenseRef-Element-Commercial
Please see LICENSE files in the repository root for full details.
*/

.mx_DeviceTile {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
}

.mx_DeviceTile_interactive {
    cursor: pointer;
}

.mx_DeviceTile_info {
    flex: 1 1 0;
}

.mx_DeviceTile_metadata {
    margin-top: $spacing-4;
    font: var(--cpd-font-body-sm-regular);
    color: $secondary-content;
}

.mx_DeviceTile_inactiveIcon {
    height: 14px;
    margin-right: $spacing-8;
    vertical-align: middle;
}

.mx_DeviceTile_actions {
    display: grid;
    grid-gap: $spacing-8;
    grid-auto-flow: column;
    margin-left: $spacing-8;
}
