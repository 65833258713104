/*
Copyright 2021-2024 New Vector Ltd.

SPDX-License-Identifier: AGPL-3.0-only OR GPL-3.0-only OR LicenseRef-Element-Commercial
Please see LICENSE files in the repository root for full details.
*/

.mx_BackdropPanel {
    position: absolute;
    left: 0;
    top: 0;
    height: 100vh;
    width: 100%;
    overflow: hidden;
    filter: blur(var(--lp-background-blur));
    /* Force a new layer for the backdropPanel so it's better hardware supported */
    transform: translateZ(0);
}

.mx_BackdropPanel--image {
    position: absolute;
    top: 0;
    left: 0;
    min-height: 100%;
    z-index: 0;
    pointer-events: none;
    overflow: hidden;
    user-select: none;
}
