/*
Copyright 2024 New Vector Ltd.
Copyright 2022 The Matrix.org Foundation C.I.C.

SPDX-License-Identifier: AGPL-3.0-only OR GPL-3.0-only OR LicenseRef-Element-Commercial
Please see LICENSE files in the repository root for full details.
*/

.mx_EnableLiveShare {
    flex: 1 1 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;

    padding: $spacing-32 $spacing-16;
    text-align: center;
    box-sizing: border-box;
}

.mx_EnableLiveShare_heading {
    padding-top: $spacing-24;
}

.mx_EnableLiveShare_icon {
    height: 58px;
    width: 58px;
}

.mx_EnableLiveShare_description {
    padding: 0 $spacing-24;
    margin-bottom: $spacing-32;
    line-height: $font-20px;
}

.mx_EnableLiveShare_button {
    margin-top: $spacing-32;
    height: 48px;
    width: 100%;
}
