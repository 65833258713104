/*
Copyright 2017-2024 New Vector Ltd.

SPDX-License-Identifier: AGPL-3.0-only OR GPL-3.0-only OR LicenseRef-Element-Commercial
Please see LICENSE files in the repository root for full details.
*/

.mx_InlineSpinner {
    display: inline;
}

.mx_InlineSpinner img,
.mx_InlineSpinner_icon {
    margin: 0px 6px;
    vertical-align: -3px;
}

.mx_InlineSpinner_icon {
    display: inline-block !important; /* Override regular mx_Spinner_icon */
}
