/*
Copyright 2019-2024 New Vector Ltd.

SPDX-License-Identifier: AGPL-3.0-only OR GPL-3.0-only OR LicenseRef-Element-Commercial
Please see LICENSE files in the repository root for full details.
*/

.mx_AuthBody_language {
    width: 100%;
}

.mx_AuthBody_language .mx_Dropdown_input {
    border: none;
    font: var(--cpd-font-body-md-semibold);
    color: $authpage-lang-color;
    width: auto;
}

.mx_AuthBody_language .mx_Dropdown_arrow {
    background: $authpage-lang-color;
}
