/*
Copyright 2024 New Vector Ltd.
Copyright 2021 Robin Townsend <robin@robin.town>

SPDX-License-Identifier: AGPL-3.0-only OR GPL-3.0-only OR LicenseRef-Element-Commercial
Please see LICENSE files in the repository root for full details.
*/

.mx_BulkRedactDialog {
    .mx_Checkbox,
    .mx_BulkRedactDialog_checkboxMicrocopy {
        line-height: $font-20px;
    }

    .mx_BulkRedactDialog_checkboxMicrocopy {
        margin-left: 26px;
        color: $secondary-content;
    }
}
