/*
Copyright 2024 New Vector Ltd.
Copyright 2020 The Matrix.org Foundation C.I.C.

SPDX-License-Identifier: AGPL-3.0-only OR GPL-3.0-only OR LicenseRef-Element-Commercial
Please see LICENSE files in the repository root for full details.
*/

.mx_CompleteSecurity_header {
    display: flex;
    align-items: center;
}

.mx_CompleteSecurity_headerIcon {
    width: 24px;
    height: 24px;
    margin-right: 4px;
    position: relative;
}

.mx_CompleteSecurity_heroIcon {
    width: 128px;
    height: 128px;
    position: relative;
    margin: 0 auto;
}

.mx_CompleteSecurity_skip {
    @mixin customisedCancelButton;
    position: absolute;
    right: 24px;
}

.mx_CompleteSecurity_body {
    font-size: $font-15px;
}

.mx_CompleteSecurity_waiting {
    color: $tertiary-content;
}

.mx_CompleteSecurity_actionRow {
    display: flex;
    justify-content: flex-end;
    margin-top: $font-28px;

    .mx_AccessibleButton {
        margin-inline-start: 18px;

        &.warning {
            color: $alert;
        }
    }
}
