/*
Copyright 2024 New Vector Ltd.
Copyright 2023 The Matrix.org Foundation C.I.C.

SPDX-License-Identifier: AGPL-3.0-only OR GPL-3.0-only OR LicenseRef-Element-Commercial
Please see LICENSE files in the repository root for full details.
*/

.mx_CryptographyPanel_sessionInfo {
    padding: 0em;
    border-spacing: 0px;
}
.mx_CryptographyPanel_sessionInfo > tr {
    vertical-align: baseline;
    padding: 0em;

    th {
        text-align: start;
    }

    td,
    th {
        padding: 0 1em 0 0;
    }
}

.mx_CryptographyPanel_importExportButtons {
    display: inline-flex;
    flex-flow: wrap;
    row-gap: $spacing-8;
    column-gap: $spacing-8;
}
