/*
Copyright 2024 New Vector Ltd.
Copyright 2023 The Matrix.org Foundation C.I.C.

SPDX-License-Identifier: AGPL-3.0-only OR GPL-3.0-only OR LicenseRef-Element-Commercial
Please see LICENSE files in the repository root for full details.
*/

.mx_NotificationSettings2 {
    .mx_SettingsSection_subSections {
        color: $primary-content;
        gap: 32px;
        display: flex;
        flex-direction: column;
    }

    .mx_SettingsSubsection_description {
        margin-bottom: 20px;

        .mx_SettingsSubsection_text {
            font-size: 1.2rem;

            .mx_NotificationBadge {
                vertical-align: baseline;
                display: inline-flex;
                margin: 0 2px;
            }
        }
    }

    .mx_SettingsSubsection_content {
        margin-top: 12px;
        grid-gap: 12px;
        justify-items: stretch;
        justify-content: stretch;
    }

    .mx_SettingsBanner {
        margin-bottom: 32px;
    }

    .mx_NotificationSettings2_flags {
        grid-gap: 4px;
    }

    .mx_StyledRadioButton_content {
        margin-left: 10px;
        margin-right: 10px;
    }

    .mx_TagComposer {
        margin-top: 16px;

        &.mx_TagComposer_disabled {
            opacity: 0.7;
        }

        .mx_TagComposer_tags {
            margin-top: 16px;
            gap: 8px;

            .mx_Tag {
                border-radius: 18px;
                padding: 6px 12px;
                background: $panel-actions;
                margin: 0;

                .mx_Tag_delete {
                    background: $tertiary-content;
                    color: #fff;
                    align-self: initial;
                }
            }
        }
    }
}
