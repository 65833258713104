/*
Copyright 2018-2024 New Vector Ltd.

SPDX-License-Identifier: AGPL-3.0-only OR GPL-3.0-only OR LicenseRef-Element-Commercial
Please see LICENSE files in the repository root for full details.
*/

.mx_DeactivateAccountDialog .mx_Dialog_content {
    margin-bottom: 30px;
}

.mx_DeactivateAccountDialog .mx_DeactivateAccountDialog_input_section .mx_Field {
    width: 300px;
}
