/*
Copyright 2024 New Vector Ltd.
Copyright 2015, 2016 OpenMarket Ltd

SPDX-License-Identifier: AGPL-3.0-only OR GPL-3.0-only OR LicenseRef-Element-Commercial
Please see LICENSE files in the repository root for full details.
*/

button.mx_BaseAvatar {
    /* <button> is a form element and by default it uses the user agent (browser) styling.
       We want it to inherit the font-family and line-height from its parent.
     */
    font-family: inherit;
    line-height: inherit;
}
