/*
Copyright 2024 New Vector Ltd.
Copyright 2022 The Matrix.org Foundation C.I.C.

SPDX-License-Identifier: AGPL-3.0-only OR GPL-3.0-only OR LicenseRef-Element-Commercial
Please see LICENSE files in the repository root for full details.
*/

.mx_MapError {
    padding: 100px $spacing-32 0;
    text-align: center;

    --mx-map-error-icon-color: $secondary-content;
    --mx-map-error-icon-size: 58px;
}

.mx_MapError.mx_MapError_isMinimised {
    height: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    padding: $spacing-24;
    background-color: $panels;
    font-size: $font-12px;
    line-height: $font-16px;

    --mx-map-error-icon-color: $alert;
    --mx-map-error-icon-size: 26px;

    .mx_MapError_message {
        margin: 0;
        max-width: 275px;
    }

    .mx_MapError_heading {
        padding-top: $spacing-8;
        /* override h3 heading size */
        font-size: inherit !important;
        font-weight: normal !important;
    }
}

.mx_MapError_message {
    margin: $spacing-16 0 $spacing-32;
}

.mx_MapError_heading {
    padding-top: $spacing-24;
}

.mx_MapError_icon {
    height: var(--mx-map-error-icon-size);
    width: var(--mx-map-error-icon-size);
    color: var(--mx-map-error-icon-color);
}
