/*
Copyright 2019-2024 New Vector Ltd.

SPDX-License-Identifier: AGPL-3.0-only OR GPL-3.0-only OR LicenseRef-Element-Commercial
Please see LICENSE files in the repository root for full details.
*/

.mx_IncomingSasDialog_opponentProfile_image {
    position: relative;
}

.mx_IncomingSasDialog_opponentProfile h2 {
    display: inline-block;
    margin-left: 10px;
}
