/*
Copyright 2024 New Vector Ltd.
Copyright 2017 Vector Creations Ltd

SPDX-License-Identifier: AGPL-3.0-only OR GPL-3.0-only OR LicenseRef-Element-Commercial
Please see LICENSE files in the repository root for full details.
*/

.mx_SetEmailDialog_email_input {
    border-radius: 3px;
    border: 1px solid $input-border-color;
    padding: 9px;
    color: $input-fg-color;
    background-color: $background;
    font-size: $font-15px;
    width: 100%;
    max-width: 280px;
    margin-bottom: 10px;
}

.mx_SetEmailDialog_email_input:focus {
    outline: none;
    box-shadow: none;
    border: 1px solid $accent;
}
