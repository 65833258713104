/*
Copyright 2024 New Vector Ltd.
Copyright 2019-2021 The Matrix.org Foundation C.I.C.

SPDX-License-Identifier: AGPL-3.0-only OR GPL-3.0-only OR LicenseRef-Element-Commercial
Please see LICENSE files in the repository root for full details.
*/

.mx_RoomUpgradeWarningDialog {
    max-width: 38vw;
    width: 38vw;

    .mx_RoomUpgradeWarningDialog_progress {
        .mx_ProgressBar {
            height: 8px;
            width: 100%;

            @mixin ProgressBarBorderRadius 8px;
        }

        .mx_RoomUpgradeWarningDialog_progressText {
            margin-top: 8px;
            font-size: $font-15px;
            line-height: $font-24px;
            color: $primary-content;
        }
    }
}

.mx_RoomUpgradeWarningDialog .mx_SettingsFlag {
    font-weight: 700;

    .mx_ToggleSwitch {
        display: inline-block;
        vertical-align: middle;
        margin-left: 8px;
        float: right;
    }

    .mx_SettingsFlag_label {
        display: inline-block;
        vertical-align: middle;
    }
}
