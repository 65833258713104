/*
Copyright 2024 New Vector Ltd.
Copyright 2022 The Matrix.org Foundation C.I.C.

SPDX-License-Identifier: AGPL-3.0-only OR GPL-3.0-only OR LicenseRef-Element-Commercial
Please see LICENSE files in the repository root for full details.
*/

.mx_ThreadSummary,
.mx_ThreadSummary_content {
    color: $secondary-content;
}

.mx_ThreadSummary,
.mx_ThreadSummary_icon {
    margin-top: $spacing-8;
}

.mx_ThreadSummary {
    min-width: 267px;
    /* leave space on both left & right gutters */
    max-width: min(calc(100% - var(--EventTile_group_line-spacing-inline-start)), 600px);
    width: fit-content;
    height: 40px;
    position: relative;
    background-color: $panels;
    padding-inline: $spacing-12 $spacing-16;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    border-radius: 8px;
    box-sizing: border-box;
    clear: both;
    overflow: hidden;
    border: 1px solid $panels; /* always render a border so the hover effect doesn't require a re-layout */
    font: var(--cpd-font-body-sm-regular);

    .mx_ThreadSummary_chevron {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        width: 60px;
        box-sizing: border-box;
        /* XXX: We use an HEXA `transparent` to work around a Safari <15.4 bug */
        background: linear-gradient(270deg, $system 50%, $system-transparent 100%);

        opacity: 0;
        transform: translateX(60px);
        transition: all 0.1s ease-in-out;

        &::before {
            content: "";
            position: absolute;
            top: 50%;
            right: var(--cpd-space-1x);
            transform: translateY(-50%);
            width: 24px;
            height: 24px;
            mask-image: url("@vector-im/compound-design-tokens/icons/chevron-right.svg");
            mask-position: center;
            mask-size: contain;
            mask-repeat: no-repeat;
            background-color: $secondary-content;
        }
    }

    &:hover,
    &:focus {
        border-color: $quinary-content;

        .mx_ThreadSummary_chevron {
            opacity: 1;
            transform: translateX(0);
        }
    }

    .mx_ThreadSummary_sender,
    .mx_ThreadSummary_content,
    .mx_ThreadSummary_replies_amount {
        line-height: var(--EventTile_ThreadSummary-line-height);
    }

    .mx_ThreadSummary_sender,
    .mx_ThreadSummary_content {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }

    .mx_ThreadSummary_sender {
        font-weight: var(--cpd-font-weight-semibold);
    }

    .mx_ThreadSummary_content {
        margin-left: $spacing-4;
        flex: 1;
    }

    .mx_ThreadSummary_replies_amount {
        @mixin ThreadRepliesAmount;
    }

    .mx_MessagePanel_narrow & {
        min-width: initial;
        max-width: 100%; /* prevent overflow */
        width: initial;
    }
}

.mx_ThreadSummary_avatar {
    margin-inline-end: $spacing-8;
    flex-shrink: 0;
}

.mx_ThreadSummary_icon {
    display: inline-block;
    margin-bottom: $spacing-8;

    &::before {
        @mixin ThreadSummaryIcon;
        vertical-align: middle;
        margin-inline-end: $spacing-8;
        margin-top: -2px;
    }

    a& {
        color: $secondary-content;
    }
}
