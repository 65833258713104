/*
Copyright 2024 New Vector Ltd.
Copyright 2019 The Matrix.org Foundation C.I.C.

SPDX-License-Identifier: AGPL-3.0-only OR GPL-3.0-only OR LicenseRef-Element-Commercial
Please see LICENSE files in the repository root for full details.
*/

.mx_InlineTermsAgreement_cbContainer {
    margin-top: var(--cpd-space-4x);
    margin-bottom: 10px;
    font: var(--cpd-font-body-md-regular);

    a {
        color: $accent;
        text-decoration: none;
    }

    .mx_InlineTermsAgreement_checkbox {
        margin-top: 10px;

        input {
            vertical-align: text-bottom;
        }
    }
}

.mx_InlineTermsAgreement_link {
    display: inline-block;
    mask-image: url("$(res)/img/external-link.svg");
    background-color: $accent;
    mask-repeat: no-repeat;
    mask-size: contain;
    width: 12px;
    height: 12px;
    margin-left: 3px;
    vertical-align: middle;
}
