/*
 * Copyright 2025 New Vector Ltd.
 *
 * SPDX-License-Identifier: AGPL-3.0-only OR GPL-3.0-only OR LicenseRef-Element-Commercial
 * Please see LICENSE files in the repository root for full details.
 */

.mx_Crypto {
    table {
        margin: var(--cpd-space-4x) 0;
        text-align: left;
        border-spacing: var(--cpd-space-2x) 0;

        thead {
            font: var(--cpd-font-heading-sm-semibold);
        }
    }
}
