/*
Copyright 2024 New Vector Ltd.
Copyright 2022 The Matrix.org Foundation C.I.C.

SPDX-License-Identifier: AGPL-3.0-only OR GPL-3.0-only OR LicenseRef-Element-Commercial
Please see LICENSE files in the repository root for full details.
*/

.mx_Marker_defaultColor {
    color: $accent;
}

.mx_Marker_border {
    width: 42px;
    height: 42px;
    border-radius: 50%;
    filter: drop-shadow(0px 3px 5px rgba(0, 0, 0, 0.2));
    background-color: currentColor;

    display: flex;
    justify-content: center;
    align-items: center;

    /* caret down */
    &::before {
        content: "";
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-top: 5px solid currentColor;
        position: absolute;
        bottom: -4px;
    }
}

.mx_Marker_icon {
    color: white;
    height: 20px;
}
