/*
Copyright 2024 New Vector Ltd.
Copyright 2021 The Matrix.org Foundation C.I.C.

SPDX-License-Identifier: AGPL-3.0-only OR GPL-3.0-only OR LicenseRef-Element-Commercial
Please see LICENSE files in the repository root for full details.
*/

.mx_SpaceChildrenPicker {
    margin: 16px 0;

    .mx_StyledRadioButton + .mx_StyledRadioButton {
        margin-top: 16px;
    }

    .mx_SearchBox {
        /* To match the space around the title */
        margin: 0 0 15px 0;
        flex-grow: 0;
        border-radius: 8px;
    }

    .mx_SpaceChildrenPicker_noResults {
        display: block;
        margin-top: 24px;
    }
}
