/*
Copyright 2018-2024 New Vector Ltd.

SPDX-License-Identifier: AGPL-3.0-only OR GPL-3.0-only OR LicenseRef-Element-Commercial
Please see LICENSE files in the repository root for full details.
*/

.mx_CreateKeyBackupDialog .mx_Dialog_title {
    /* TODO: Consider setting this for all dialog titles. */
    margin-bottom: 1em;
}

.mx_CreateKeyBackupDialog_primaryContainer {
    /* FIXME: plinth colour in new theme(s). background-color: $accent; */
    padding: 20px;
}

.mx_CreateKeyBackupDialog_primaryContainer::after {
    content: "";
    clear: both;
    display: block;
}

.mx_CreateKeyBackupDialog_passPhraseContainer {
    display: flex;
    align-items: flex-start;
}

.mx_CreateKeyBackupDialog_passPhraseInput {
    flex: none;
    width: 250px;
    border: 1px solid $accent;
    border-radius: 5px;
    padding: 10px;
    margin-bottom: 1em;
}

.mx_CreateKeyBackupDialog_passPhraseMatch {
    margin-left: 20px;
}

.mx_CreateKeyBackupDialog_recoveryKeyHeader {
    margin-bottom: 1em;
}

.mx_CreateKeyBackupDialog_recoveryKeyContainer {
    display: flex;
}

.mx_CreateKeyBackupDialog_recoveryKey {
    width: 262px;
    padding: 20px;
    color: $info-plinth-fg-color;
    background-color: $info-plinth-bg-color;
    margin-right: 12px;
}

.mx_CreateKeyBackupDialog_recoveryKeyButtons {
    flex: 1;
    display: flex;
    align-items: center;
}

.mx_CreateKeyBackupDialog_recoveryKeyButtons button {
    flex: 1;
    white-space: nowrap;
}

.mx_CreateKeyBackupDialog {
    details .mx_AccessibleButton {
        margin: 1em 0; /* emulate paragraph spacing because we can't put this button in a paragraph due to HTML rules */
    }
}
