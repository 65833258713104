/*
Copyright 2024 New Vector Ltd.
Copyright 2017 Vector Creations Ltd.

SPDX-License-Identifier: AGPL-3.0-only OR GPL-3.0-only OR LicenseRef-Element-Commercial
Please see LICENSE files in the repository root for full details.
*/

.mx_ConfirmUserActionDialog .mx_Dialog_content .mx_ConfirmUserActionDialog_user {
    min-height: 48px;
    margin-bottom: 24px;
}

.mx_ConfirmUserActionDialog_avatar {
    float: left;
    margin-right: 20px;
}

.mx_ConfirmUserActionDialog_name {
    padding-top: 2px;
    font-size: $font-18px;
}

.mx_ConfirmUserActionDialog_userId {
    font-size: $font-13px;
}

.mx_ConfirmUserActionDialog_reasonField {
    font: var(--cpd-font-body-md-regular);
    color: $primary-content;
    background-color: $background;
}
