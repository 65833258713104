/*
Copyright 2024 New Vector Ltd.
Copyright 2021 The Matrix.org Foundation C.I.C.

SPDX-License-Identifier: AGPL-3.0-only OR GPL-3.0-only OR LicenseRef-Element-Commercial
Please see LICENSE files in the repository root for full details.
*/

.mx_DialPadBackspaceButton {
    position: relative;
    height: 28px;
    width: 28px;

    &::before {
        /* force this element to appear on the DOM */
        content: "";

        background-color: #8d97a5;
        width: inherit;
        height: inherit;
        top: 0px;
        left: 0px;
        position: absolute;
        display: inline-block;
        vertical-align: middle;

        mask-image: url("$(res)/img/element-icons/call/delete.svg");
        mask-position: 8px;
        mask-size: 20px;
        mask-repeat: no-repeat;
    }
}
