/*
Copyright 2024 New Vector Ltd.
Copyright 2018, 2019 , 2021 The Matrix.org Foundation C.I.C.

SPDX-License-Identifier: AGPL-3.0-only OR GPL-3.0-only OR LicenseRef-Element-Commercial
Please see LICENSE files in the repository root for full details.
*/

.mx_AccessSecretStorageDialog {
    .mx_AccessSecretStorageDialog_titleWithIcon {
        &::before {
            content: "";
            display: inline-block;
            width: 24px;
            height: 24px;
            margin-inline-end: $spacing-8;
            position: relative;
            top: 5px;
            background-color: $primary-content;
        }

        &.mx_AccessSecretStorageDialog_resetBadge::before {
            /* The image isn't capable of masking, so we use a background instead. */
            background-image: url("@vector-im/compound-design-tokens/icons/error-solid.svg");
            background-size: 24px;
            background-color: transparent;
        }

        &.mx_AccessSecretStorageDialog_secureBackupTitle::before {
            mask-image: url("$(res)/img/feather-customised/secure-backup.svg");
        }

        &.mx_AccessSecretStorageDialog_securePhraseTitle::before {
            mask-image: url("$(res)/img/feather-customised/secure-phrase.svg");
        }
    }

    .mx_AccessSecretStorageDialog_primaryContainer {
        .mx_AccessSecretStorageDialog_passPhraseInput {
            width: 300px;
            border: 1px solid $accent;
            border-radius: 5px;
        }

        .mx_AccessSecretStorageDialog_keyStatus {
            height: 30px;
        }

        .mx_AccessSecretStorageDialog_recoveryKeyEntry {
            display: flex;
            align-items: center;

            .mx_AccessSecretStorageDialog_recoveryKeyEntry_textInput {
                flex-grow: 1;
            }

            .mx_AccessSecretStorageDialog_recoveryKeyEntry_entryControlSeparatorText {
                margin: $spacing-16;
            }

            .mx_AccessSecretStorageDialog_recoveryKeyEntry_fileInput {
                display: none;
            }
        }

        .mx_AccessSecretStorageDialog_recoveryKeyFeedback {
            &::before {
                content: "";
                display: inline-block;
                vertical-align: bottom;
                width: 20px;
                height: 20px;
                mask-repeat: no-repeat;
                mask-position: center;
                mask-size: 20px;
                margin-inline-end: 5px;
            }

            &.mx_AccessSecretStorageDialog_recoveryKeyFeedback--valid {
                color: $accent;

                &::before {
                    mask-image: url("@vector-im/compound-design-tokens/icons/check.svg");
                    background-color: $accent;
                }
            }

            &.mx_AccessSecretStorageDialog_recoveryKeyFeedback--invalid {
                color: $alert;

                &::before {
                    mask-image: url("@vector-im/compound-design-tokens/icons/close.svg");
                    background-color: $alert;
                }
            }
        }

        .mx_Dialog_buttons {
            $spacingStart: $spacing-24; /* 16px icon + 8px padding */

            text-align: initial;
            display: flex;
            flex-flow: column;
            gap: 14px;

            .mx_Dialog_buttons_additive {
                float: none;

                .mx_AccessSecretStorageDialog_reset {
                    position: relative;
                    padding-inline-start: $spacingStart;
                    /* To avoid bold styling inherent with <strong> elements */
                    font-weight: inherit;

                    &::before {
                        content: "";
                        display: inline-block;
                        position: absolute;
                        height: 16px;
                        width: 16px;
                        left: 0;
                        top: 2px; /* alignment */
                        background-image: url("@vector-im/compound-design-tokens/icons/error-solid.svg");
                        background-size: contain;
                    }

                    .mx_AccessSecretStorageDialog_reset_link {
                        color: $alert;
                    }
                }
            }

            .mx_Dialog_buttons_row {
                gap: $spacing-16; /* TODO: needs normalization */
                padding-inline-start: $spacingStart;
            }
        }
    }
}
