/*
Copyright 2024 New Vector Ltd.
Copyright 2019 The Matrix.org Foundation C.I.C.

SPDX-License-Identifier: AGPL-3.0-only OR GPL-3.0-only OR LicenseRef-Element-Commercial
Please see LICENSE files in the repository root for full details.
*/

.mx_ErrorBoundary {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.mx_ErrorBoundary_body {
    display: flex;
    flex-direction: column;
    max-width: 400px;
    align-items: center;

    .mx_AccessibleButton {
        margin-top: 5px;
    }
}
