/*
Copyright 2019-2024 New Vector Ltd.

SPDX-License-Identifier: AGPL-3.0-only OR GPL-3.0-only OR LicenseRef-Element-Commercial
Please see LICENSE files in the repository root for full details.
*/

.mx_SettingsDialog_toastContainer {
    position: absolute;
    bottom: var(--cpd-space-10x);
    width: 100%;
    display: flex;
    justify-content: center;
}

.mx_UserSettingsDialog_title {
    /* Override default dialog font style */
    font: var(--cpd-font-heading-md-regular) !important;

    .mx_UserSettingsDialog_title_strong {
        font: var(--cpd-font-heading-md-semibold);
    }
}
