/*
Copyright 2024 New Vector Ltd.
Copyright 2020 The Matrix.org Foundation C.I.C.

SPDX-License-Identifier: AGPL-3.0-only OR GPL-3.0-only OR LicenseRef-Element-Commercial
Please see LICENSE files in the repository root for full details.
*/

.mx_ModalWidgetDialog {
    .mx_ModalWidgetDialog_warning {
        margin-bottom: 24px;

        > img {
            vertical-align: middle;
            margin-right: 8px;
        }
    }

    .mx_ModalWidgetDialog_buttons {
        float: right;
        margin-top: 24px;

        .mx_AccessibleButton + .mx_AccessibleButton {
            margin-left: 8px;
        }
    }

    iframe {
        width: 100%;
        height: 450px;
        border: 0;
        border-radius: 8px;
    }
}
