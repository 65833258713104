/*
Copyright 2024 New Vector Ltd.
Copyright 2019, 2020 The Matrix.org Foundation C.I.C.

SPDX-License-Identifier: AGPL-3.0-only OR GPL-3.0-only OR LicenseRef-Element-Commercial
Please see LICENSE files in the repository root for full details.
*/

.mx_EventTileBubble.mx_cryptoEvent {
    margin: var(--EventTileBubble_margin-block) auto;

    /* white infill for the transparency */
    &.mx_cryptoEvent_icon::before {
        background-color: #ffffff;
        mask-image: url("$(res)/img/e2e/normal.svg");
        mask-repeat: no-repeat;
        mask-position: center;
        mask-size: 80%;
    }

    &.mx_cryptoEvent_icon::after {
        mask-image: url("$(res)/img/e2e/normal.svg");
        background-color: $header-panel-text-primary-color;
    }

    &.mx_cryptoEvent_icon_verified::after {
        mask-image: url("$(res)/img/e2e/verified.svg");
        background-color: $accent;
    }

    &.mx_cryptoEvent_icon_warning::after {
        mask-image: url("$(res)/img/e2e/warning.svg");
        background-color: $e2e-warning-color;
    }

    .mx_cryptoEvent_state,
    .mx_cryptoEvent_buttons {
        grid-column: 3;
        grid-row: 1 / 3;
    }

    .mx_cryptoEvent_buttons {
        align-items: center;
        display: flex;
        gap: 5px;
    }

    .mx_cryptoEvent_state {
        width: 130px;
        padding: 10px 20px;
        margin: auto 0;
        text-align: center;
        color: $tertiary-content;
        overflow-wrap: break-word;
        font-size: $font-12px;
    }
}
