/*
Copyright 2024 New Vector Ltd.
Copyright 2019 The Matrix.org Foundation C.I.C.

SPDX-License-Identifier: AGPL-3.0-only OR GPL-3.0-only OR LicenseRef-Element-Commercial
Please see LICENSE files in the repository root for full details.
*/

.mx_SetIntegrationManager {
    .mx_SetIntegrationManager_heading_manager {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        column-gap: $spacing-4;
    }
    form {
        margin-top: var(--cpd-space-3x);
    }
}
