/*
Copyright 2024 New Vector Ltd.

SPDX-License-Identifier: AGPL-3.0-only OR GPL-3.0-only OR LicenseRef-Element-Commercial
Please see LICENSE files in the repository root for full details.
*/

.mx_MemberListView {
    flex: 1;
    display: flex;
    flex-direction: column;
    min-height: 0;

    .mx_MemberListView_container {
        height: 100%;
    }

    .mx_MemberListView_separator {
        margin: 0;
        border: none;
        border-top: 2px solid var(--cpd-color-bg-subtle-primary);
    }
}
