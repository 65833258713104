/*
Copyright 2024 New Vector Ltd.
Copyright 2021 The Matrix.org Foundation C.I.C.

SPDX-License-Identifier: AGPL-3.0-only OR GPL-3.0-only OR LicenseRef-Element-Commercial
Please see LICENSE files in the repository root for full details.
*/

.mx_GenericFeatureFeedbackDialog {
    .mx_GenericFeatureFeedbackDialog_subheading {
        color: $primary-content;
        font: var(--cpd-font-body-md-regular);
        line-height: $font-20px;
        margin-bottom: 24px;
    }
}
